import React from 'react'

const Css = `
body {
    background: #fff;
}
.selected {
    background: #222;
    color: #fff;
    border-radius: 4px;
    padding: 0px 5px 3px 4px;
    }
.content {
    font-weight: 400;
    font-family: 'EB Garamond';
    font-size: 2vw;
    line-height: 140%;
}

.content h1 {
    margin-bottom: 6rem;
    font-size: 3.2vw;
}
.content h2 {
    font-size: 2.2vw;
}
h3 {
    margin-top: 2em;
}
blockquote {
    font-size: 85%;
    margin-block-start: 1.2em;
    margin-block-end: 1.2em;
    margin-inline-start: 60px;
    margin-inline-end: 60px;
    line-height: 150%;
}
@media (max-width: 700px) {
    .content {
        font-size: 20px;
    }
    .content h1 {
        font-size: 30px;
    }
    .content h2 {
        font-size: 22px;
    }
    img {
        max-width: 70vw;
    }
    blockquote {
        font-size: 85%;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        margin: 20px;
        line-height: 150%;
    }
    table {
        display: content !important;
        margin: 0;
        width: auto;
        max-width: 60vw;
    }
}

a, a:visited {
    color: #000 !important;
}
a:hover {
    color: #cc0000 !important;
}
.contentBody a,
.contentBody a:hover,
.contentBody a:visited {
    color: #cc0000 !important;
}

`

export default Css
