import { graphql, Link } from 'gatsby'
import * as React from 'react'
import Layout from './layout'
import styled from 'styled-components'
import Css from './css'
import { filterHtml } from '../utils'
// import Seo from "../components/seo";

const StyledLink = styled(Link)`
  position: relative;
  text-decoration: none;
  color: #000 !important;
  &:hover {
    text-decoration: none !important;
  }
`
export default function DevPageTemplate({
  data: { markdownRemark, allFile, file },
}) {
  // const { frontmatter, html } = markdownRemark
  const { frontmatter, html } = file.childMarkdownRemark
  const { relativeDirectory } = file
  var filteredHtml = filterHtml(allFile, html)
  console.log('directory', file, relativeDirectory)
  return (
    <>
      <style>{Css}</style>
      <Layout>
        {relativeDirectory.startsWith('la-letteratura-combinatoria') ? (
          <>
            <StyledLink to={'/scritti/la-letteratura-combinatoria'}>
              <h1>La letteratura combinatoria</h1>
            </StyledLink>
            <h2>{frontmatter.title}</h2>
          </>
        ) : (
          <>{frontmatter.title !== '' && <h1>{frontmatter.title}</h1>}</>
        )}
        <div
          className="contentBody"
          dangerouslySetInnerHTML={{ __html: filteredHtml }}
        />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query ($permalink: String!) {
    file(
      childMarkdownRemark: { frontmatter: { permalink: { eq: $permalink } } }
    ) {
      childMarkdownRemark {
        frontmatter {
          title
        }
        html
      }
      relativeDirectory
    }
    markdownRemark(frontmatter: { permalink: { eq: $permalink } }) {
      html
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        permalink
        title
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "uploads" } }) {
      edges {
        node {
          publicURL
          relativePath
        }
      }
    }
  }
`
